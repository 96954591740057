// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  padding: 20px;
  text-align: center;
  background-color: #333;
  color: #fff;
}

.dark-mode .footer {
  background-color: #222;
  color: #e0e0e0;
}
`, "",{"version":3,"sources":["webpack://./src/components/Footer.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,sBAAsB;EACtB,cAAc;AAChB","sourcesContent":[".footer {\n  padding: 20px;\n  text-align: center;\n  background-color: #333;\n  color: #fff;\n}\n\n.dark-mode .footer {\n  background-color: #222;\n  color: #e0e0e0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
